import React, { Component } from "react"

import './App.css'

class App extends Component {
  render() {
    return (
      <div>
        <h1 className="heading">How much does Artifict 2.0 cost?</h1>
        <h2 className="total">We'll see <span role="img" aria-label="shrug">🤷‍♂️</span></h2>
      </div>
    )
  }
}

export default App
